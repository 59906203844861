<template>
  <v-container>
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="5"
      >
        <validation-observer ref="observer">
          <v-form
            ref="form"
            lazy-validation
            :disabled="isSubmitting"
            @submit.prevent="toCheckout"
          >
            <v-card>
              <v-card-title>
                <v-icon left>
                  mdi-credit-card-lock-outline
                </v-icon>
                Landing Fee Payment
              </v-card-title>
              <v-card-subtitle>at {{ airfieldName }}</v-card-subtitle>

              <v-card-text>
                <p class="text-body-1">
                  Enter your PIC and Aircraft information.
                </p>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    label="Name of PIC"
                    autocomplete="name"
                    required
                    v-model="captainName"
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|email"
                >
                  <v-text-field
                    outlined
                    label="Email of PIC"
                    type="email"
                    autocomplete="email"
                    required
                    v-model="captainEmail"
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    label="Aircraft Registration"
                    required
                    v-model="aircraftReg"
                    :error-messages="errors"
                  />
                </validation-provider>

                <landing-fee-form
                  :config="landingFeesConfig"
                  :on-fee-selected="onLandingFeeSelected"
                />

                <div class="py-4 d-flex justify-space-between">
                  <span
                    class="d-inline-block v-label v-label--active text-body-1 align-self-center"
                  >
                    Number of Landings
                  </span>
                  <span class="d-flex v-label v-label--active text-body-1 align-self-center">
                    <v-btn
                      fab
                      small
                      color="primary"
                      @click="decrementLandingQty"
                      :disabled="landingFee.qty <= 1"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <span
                      aria-label="Number of Landings"
                      class="text-body-1 font-weight-bold mx-4 align-self-center"
                    >
                      {{ landingFee.qty }}
                    </span>
                    <v-btn
                      fab
                      small
                      color="primary"
                      @click="incrementLandingQty"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </span>
                </div>

                <v-divider v-if="showParkingFees" />

                <div
                  v-if="showParkingFees"
                  class="py-4 d-flex justify-space-between"
                >
                  <span
                    class="d-inline-block v-label v-label--active text-body-1 align-self-center"
                  >
                    Nights of Parking
                  </span>
                  <span
                    class="d-inline-block v-label v-label--active text-body-1 align-self-center"
                  >
                    <v-btn
                      v-if="parkingFee.qty === 0"
                      color="primary"
                      rounded
                      small
                      @click="incrementParkingQty"
                    >
                      Add Overnight Parking
                    </v-btn>
                    <span
                      v-if="parkingFee.qty > 0"
                      class="d-flex align-self-center"
                    >
                      <v-btn
                        fab
                        small
                        color="primary"
                        @click="decrementParkingQty"
                        :disabled="parkingFee.qty <= 0"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span
                        aria-label="Nights of Parking"
                        class="text-body-1 font-weight-bold mx-4 align-self-center"
                      >
                        {{ parkingFee.qty }}
                      </span>
                      <v-btn
                        fab
                        small
                        color="primary"
                        @click="incrementParkingQty"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </span>
                  </span>
                </div>

                <parking-fee-form
                  v-if="showParkingFees && parkingFee.qty > 0"
                  :config="parkingFeesConfig"
                  :on-fee-selected="onParkingFeeSelected"
                />

                <v-divider />

                <p class="text-body-1 mt-4">
                  Once you have filled in all required information, please
                  continue to our secure checkout page to make your payment.
                </p>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  rounded
                  type="submit"
                  :loading="isSubmitting"
                  :disabled="totalFee === null"
                >
                  <span v-if="totalFee == null">
                    Select a Fee Above
                  </span>
                  <span v-else-if="totalFee === 0.0">
                    Free Landing!
                  </span>
                  <span v-else-if="totalFee > 0.0">
                    Checkout
                    {{ $n(totalFee, "currency") }}
                  </span>
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import base64url from "base64url";
import {supabase} from "@/supabase";
import LandingFeeForm from "@/components/landingFees/LandingFeeForm.vue";
import ParkingFeeForm from "@/components/landingFees/ParkingFeeForm.vue";
import {RouteGenerator} from "@/router/route-generator";
import {email, required} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "LandingFee",
  props: {
    payload: {type: String, required: true}
  },

  components: {
    LandingFeeForm,
    ParkingFeeForm,
    ValidationProvider,
    ValidationObserver
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
    extend("email", {
      ...email,
      message: "Invalid email"
    });

    try {
      const jsonPayload = base64url.decode(this.payload);
      this.instanceData = JSON.parse(jsonPayload);
      this.prefillForm();
      this.fetchFeeConfig();
    } catch (_) {
      // do nothing
    }
  },

  data: () => ({
    instanceData: {},
    airfieldName: "",
    landingFeesConfig: {},
    parkingFeesConfig: {},
    showParkingFees: false,
    isSubmitting: false,
    captainName: null,
    captainEmail: null,
    aircraftReg: null,
    landingFee: {
      name: null,
      fee: null,
      qty: 1
    },
    parkingFee: {
      name: null,
      fee: null,
      qty: 0
    },
    totalFee: null,
  }),

  watch: {
    aircraftReg: function (val) {
      this.aircraftReg = val.toUpperCase();
    },
  },

  methods: {
    prefillForm() {
      if (this.instanceData.captain_name) {
        this.captainName = this.instanceData.captain_name;
      }
      if (this.instanceData.captain_email) {
        this.captainEmail = this.instanceData.captain_email;
      }
      if (this.instanceData.aircraft_reg) {
        this.aircraftReg = this.instanceData.aircraft_reg;
      }
    },

    async fetchFeeConfig() {
      const {data, error} = await supabase.functions.invoke("airfields-publicConfig", {
        body: {id: this.instanceData.airfield_id}
      });

      if (error) console.error(error);

      if (data) {
        this.airfieldName = data.name;
        this.landingFeesConfig = data.landing_fees_config || {};
        this.parkingFeesConfig = data.parking_fees_config || {};
        this.initLandingFee();
        this.initParkingFee();
        this.calculateTotalFee();
      }
    },

    initLandingFee() {
      if (this.landingFeesConfig.mode === "flat-fee") {
        this.onLandingFeeSelected("Landing Fee", this.landingFeesConfig.flatFee.fee);
      }
    },

    initParkingFee() {
      if (this.parkingFeesConfig.mode === "flat-fee") {
        this.onParkingFeeSelected("Overnight Parking Fee", this.parkingFeesConfig.flatFee.fee);
      }
      if (this.parkingFeesConfig && this.parkingFeesConfig.mode) this.showParkingFees = true;
    },

    onLandingFeeSelected(name, fee) {
      this.landingFee.name = name;
      this.landingFee.fee = fee;
      this.calculateTotalFee();
    },
    decrementLandingQty() {
      if (this.landingFee.qty > 1) {
        this.landingFee.qty -= 1;
      }
      this.calculateTotalFee();
    },
    incrementLandingQty() {
      this.landingFee.qty += 1;
      this.calculateTotalFee();
    },

    onParkingFeeSelected(name, fee) {
      this.parkingFee.name = name;
      this.parkingFee.fee = fee;
      this.calculateTotalFee();
    },
    decrementParkingQty() {
      if (this.parkingFee.qty > 0) {
        this.parkingFee.qty -= 1;
      }
      this.calculateTotalFee();
    },
    incrementParkingQty() {
      this.parkingFee.qty += 1;
      this.calculateTotalFee();
    },

    calculateTotalFee() {
      if (this.totalFee !== null) this.totalFee = null;

      if (this.landingFee.qty > 0 && this.landingFee.fee !== null) {
        if (this.totalFee === null) this.totalFee = 0.0;
        this.totalFee += this.landingFee.fee * this.landingFee.qty;
      }
      if (this.parkingFee.qty > 0 && this.parkingFee.fee !== null) {
        if (this.totalFee === null) this.totalFee = 0.0;
        this.totalFee += this.parkingFee.fee * this.parkingFee.qty;
      }
    },

    toCheckout() {
      this.isSubmitting = true;

      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            const {data, error} = await supabase.functions.invoke("stripe-createSession", {
              body: {
                airfieldId: this.instanceData.airfield_id,
                arrivalId: this.instanceData.arrival_id,
                captainName: this.captainName,
                captainEmail: this.captainEmail,
                aircraftReg: this.aircraftReg,
                landingFee: this.landingFee,
                parkingFee: this.parkingFee,
                successUrl: RouteGenerator.paymentSuccessUrl(this.instanceData.airfield_id, this.aircraftReg),
                cancelUrl: `https://airfieldhub.com${this.$route.fullPath}`
              }
            });

            if (error) console.error(error);

            if (data?.redirectUrl) {
              window.location.href = data.redirectUrl;
            }
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
