import base64url from "base64url";

export class RouteGenerator {
  static _baseUrl() {
    return "https://airfieldhub.com";
  }

  static paymentLink(airfieldId) {
    const jsonPayload = JSON.stringify({
      airfield_id: airfieldId
    });
    const payload = base64url.encode(jsonPayload);

    return `${this._baseUrl()}/link/payment/landing-fee/${payload}/`;
  }

  static paymentSuccessUrl(airfieldId, aircraftReg) {
    const jsonPayload = JSON.stringify({
      airfield_id: airfieldId,
      aircraft_reg: aircraftReg
    });
    const payload = base64url.encode(jsonPayload);

    return `${this._baseUrl()}/link/payment/landing-fee/success/${payload}/`;
  }
}
