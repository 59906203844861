<template>
  <v-container>
    <v-row justify="center">
      <v-col
        sm="8"
        md="6"
        lg="5"
      >
        <v-card>
          <v-card-title>
            <v-icon left>
              mdi-check-decagram
            </v-icon>
            Successful Payment
          </v-card-title>
          <v-card-text>
            <p class="text-body-1">
              Thank you for paying your fees online, the airfield team
              has been notified. We hope you are enjoying the airfield, and we
              hope to see you again soon!
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import base64url from "base64url";

export default {
  name: "LandingFeeSuccess",
  props: {
    payload: { type: String, required: true }
  },

  created() {
    try {
      const jsonPayload = base64url.decode(this.payload);
      this.instanceData = JSON.parse(jsonPayload);
    } catch (_) {
      // do nothing
    }
  },

  data: () => ({
    instanceData: {}
  }),

  computed: {
    airfieldId() {
      return this.instanceData.airfieldId || undefined;
    },
    aircraftReg() {
      return this.instanceData.aircraftReg || undefined;
    }
  }
};
</script>
