<template>
  <div>
    <div v-if="config.mode == 'flat-fee'">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>All landings</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="font-weight-regular">
            <span v-if="config.flatFee.fee == 0.0">Free</span>
            <i18n-n
              v-else
              :value="config.flatFee.fee"
              format="currency"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="config.mode == 'selectable-rules'">
      <h4 class="text-body-1 mb-2">
        Select your landing fee from the following options.
      </h4>
      <v-list>
        <v-list-item-group
          v-model="selectableRuleSelected"
          @change="onSelectableRuleSelected"
        >
          <v-list-item
            v-for="(rule, i) in config.selectableRules"
            :key="i"
            :three-line="!!rule.description"
          >
            <v-list-item-icon>
              <v-icon v-if="selectableRuleSelected != i">
                mdi-radiobox-blank
              </v-icon>
              <v-icon v-if="selectableRuleSelected == i">
                mdi-radiobox-marked
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ rule.title }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ rule.description || "" }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="font-weight-regular">
              <span v-if="rule.fee == 0.0">Free</span>
              <i18n-n
                v-else
                :value="rule.fee"
                format="currency"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <div v-if="config.mode == 'mtow-rules'">
      <h4 class="text-body-1 mb-2">
        Select your landing fee for the MTOW of your aircraft.
      </h4>
      <v-list>
        <v-list-item-group
          v-model="mtowRuleSelected"
          @change="onMtowRuleSelected"
        >
          <v-list-item
            v-for="(rule, i) in config.mtowRules"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-if="mtowRuleSelected != i">
                mdi-radiobox-blank
              </v-icon>
              <v-icon v-if="mtowRuleSelected == i">
                mdi-radiobox-marked
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="i == 0">
                  0 kg &nbsp;<v-icon x-small>mdi-arrow-right</v-icon>&nbsp;
                </span>
                <span v-else-if="config.mtowRules[i - 1]">
                  <i18n-n :value="config.mtowRules[i - 1].max + 1" /> kg
                  &nbsp;<v-icon x-small>mdi-arrow-right</v-icon>&nbsp;
                </span>
                <i18n-n :value="rule.max" /> kg
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="font-weight-regular">
              <span v-if="rule.fee == 0.0">Free</span>
              <i18n-n
                v-else
                :value="rule.fee"
                format="currency"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingFeeForm",
  props: {
    config: { type: Object, required: true },
    onFeeSelected: { type: Function, required: true }
  },

  data() {
    return {
      selectableRuleSelected: null,
      mtowRuleSelected: null
    };
  },

  methods: {
    onSelectableRuleSelected(index) {
      const rule = this.config.selectableRules[index];
      if (rule) {
        this.onFeeSelected(rule.title, rule.fee);
      } else {
        this.onFeeSelected(null, null);
      }
    },
    onMtowRuleSelected(index) {
      const rule = this.config.mtowRules[index];
      if (rule) {
        this.onFeeSelected(this._mtowName(index, rule), rule.fee);
      } else {
        this.onFeeSelected(null, null);
      }
    },
    _mtowName(index, rule) {
      if (index == 0) {
        return `0 kg to ${this.$i18n.n(rule.max)} kg`;
      } else {
        const prevRule = this.config.mtowRules[index - 1];
        return (
          `${this.$i18n.n(prevRule.max + 1)} kg` +
          ` to ` +
          `${this.$i18n.n(rule.max)} kg`
        );
      }
    }
  }
};
</script>
